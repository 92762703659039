.experienceContainer {
    width: 100%;
    margin-bottom: 25px;
}

h2 {
    padding-bottom: 5px;
    /* padding-top: 5px; */

}
.experienceHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.experienceHeader > h2, .experienceHeader > p {
    color: #99CCFF;
    margin-top: 20px;
}

.experienceItem  {
    /* padding: 0; */
    margin: 10px 0px 10px 0px;
}