.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    background-color: #222326;
    padding: 1%;
    border-radius: 5px;    
    max-width: 50%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    /* height: 70vh; */
    justify-content: space-between;

}

.exitBtn {
    display: flex;
    top: 5%;
    right: 5%;
    background: transparent;
    display: flex;
    justify-content: right;
    padding-bottom: 2%;
}

.exitIcon {
    background-color: #222326;
    font-size: 2em;
}

.exitIcon:hover {
    cursor: pointer;
    background-color: #222326;
    fill: #3366CC;
    transition: fill 0.3s, background-color 0.3s, fill 0.3s; 
}

.titleContainer {
    display: flex;
    background: transparent;
    justify-content: center;
}

.modalTitle{
    background: transparent;
}

.body {
    /* width: 100%;
    height: 80%; */
    /* padding-top: 2%;
    padding-bottom: 2%; */
    background: transparent;
    /* box-sizing: border-box; */
    /* border: 2px solid #3366CC; */
    /* border-radius: 5px; */
}

.footer {
    background-color: #222326;
    display: flex;
    justify-content: space-between;
    padding-top: 2%;
    align-items: center;
}


.cancelBtn,
.goBtn {
    width: auto;
    cursor: pointer;
    padding: 1% 2%;
    border: 1px solid #ffffff;
    border-radius: 4px;
}

.cancelBtn:hover {
    background-color: #222326;
    border-color: black;
    color: #ad9031;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.goBtn:hover {
    background-color: #222326;
    border-color: black;
    color: #3366CC;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.maintenance-notice {
    background-color: transparent;
}