.App {
    text-align: center;
    display: flex;
    width: 100%;
}

.navbar {
    display: flex;
    flex-direction: column;
}

.navbar li {
    font-weight: lighter;
}


.awsContainer {
    position: absolute;
    bottom: 0;
    margin-bottom: 5%;
    /* width: 0%; */
    background-color: #222326;
    display: flex;
    align-items: center;
    justify-content: center;
}

.awsIcon {
    background-color: #222326;
    font-size: 200%;
    padding: 5%;
}

.about, .projects, .theses, .experience {
    width: 100%;
    margin-bottom: 1em;
    padding: 1em;
}

.contact {
    width: 100%;
    margin-bottom: 15%;
    padding: 1em;
}

.link, .link-viewed {
    text-decoration: none;
    padding: 20px 20px;
    font-weight: 700;
    font-size: large;
    background-color: #222326;

}

.link-viewed {
    font-weight: 400;
    color: #3366CC;
    transition: color 0.3s;
}

.main, .navbar, .navbar > h1, .navbar > h2, .navbar > ul {
    background-color: #222326;
}

.main {
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 60%;
    margin-top: 5%;
    margin-left: 30%;

    display: flex;
    flex-direction: column;
    text-align: left;
  }

.aboutContainer, .projectContainer {
    display: flex;
    flex-direction: column;
} 

.projectContainer {
    align-items: center;
} 

.projectTitle, .aboutTitle, .thesesTitle {
    text-align: left;
    width: 100%;
    margin-bottom: 5%;
}

.contact {
    display: flex;
}


*, html {
    scroll-behavior: smooth !important;
    font-family: "Rubik", sans-serif;
    background: #333333;
}

html {
    margin: 1%;
    height: 100%;
}

* {
    color: #f5f8ff;
}

h2 {
    font-weight: 400;
}

p {
    font-weight: 100;
}


li:hover {
    cursor: pointer;
    font-weight: normal;
    transition: font-weight 0.3s;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.icons {
    display: flex;
    /* padding: 15%; */
    background-color: #222326;
    justify-content: center;
    /* align-items: center; */
}


.gitIcon, .linkedinIcon, .personIcon {
    background-color: #222326;
    font-size: 3em;
    margin-top: 0.25em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    padding: 1.5%;
    border-radius: 25%;
}

.gitIcon:hover, .linkedinIcon:hover, .personIcon:hover {
    cursor: pointer;
    fill: #222326;
    background-color: #3366CC;
    transition: fill 0.3s, background-color 0.3s, fill 0.3s; 
}

.gitIcon:hover {
    border-radius: 50%;
}

.toggle-button {
    visibility: hidden;
}

@media only screen and (max-width: 805px) and (orientation: portrait) {
    
    .toggle-button {
        visibility: visible;
        
        position: fixed;
        z-index: 2;
        top: 2%;
        left: 10%;
        padding: 1% 1%;
        cursor: pointer;
        background-color: transparent;
        /* background-color: #3366CC; */
        border: none;
        border-radius: 5px;
    }

    .main {
        position: fixed;
        width: 50%;
        height: 100%;
        left: -50%;
        transition: left 0.3s ease;
        z-index: 1;
        background-color: #222326;
        align-items: flex-start;
        padding-top: 15%;
        justify-content: center;
    }
    
    .slide-in {
        left: 0;
        transition: left 0.3s ease;
    }
    
    .slide-out {
        left: -50%;
        transition: left 0.3s ease;
    }
    
    .sidebar {
        width: 50%;
        flex-direction: row;
        background-color: #222326;
        align-items: center;
        text-align: left;
    }

    .content {
        width: 100%;
        margin-top: 5%;
        margin-left: 0;
        padding: 1em;
    }

    .main-text {
        margin-top: 50%;
    }

    .gitIcon, .linkedinIcon, .personIcon {
        font-size: 2em;
        margin-top: 0.5em;
    }

    .awsContainer {
        position: absolute;
        bottom: 0;
        margin-bottom: 50%;
        background-color: #222326;
    }
    
    .awsIcon {
        background-color: #222326;
        font-size: 200%;
        /* padding: 10%; */
    }

    .barsIcon {
        font-size: 200%;
        background-color: #3366CC;
        padding: 15%;
        border-radius: 25%;
        /* background-color: transparent; */
    }
    .navbar {
        /* margin-left: 5%; */
        width: 100%;
    }

    .navbar ul {
        padding-left: 0;
    }

    .navbar li {
        font-weight: lighter;
        padding: 10% 0;
        margin-bottom: 2%;
    }
}
