.formContainer {
    margin: auto auto;
}

form {
    padding: 50px;
    border: 2px solid rgba(51, 102, 204, 0.5);
    border-radius: 5px;
}

input, textarea {
    width: 100%;
    padding: 20px;
    border: 1px solid #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    margin: auto auto;
    margin-bottom: 10px;
}

textarea {
    resize: vertical;
}

input[type="submit"] {
    width: auto;
    cursor: pointer;
    /* padding: 10px 20px */
    padding: 25% 35%;
}

input[type="submit"]:hover {
    background-color: #222326;
    border-color: black;
    color: #3366CC;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.footerContainer {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}